import Vue from 'vue'
import VueRouter from 'vue-router'
import Stripe from '../view/Stripe.vue'
import Paypal from '../view/Paypal.vue'
import Razorpay from '../view/Razorpay.vue'
import Receipt from '../components/Receipt/index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: "",
        name: "index",
        component: () => import("../view/app/index"),
        children: [
            {
                path: '/receipt',
                name: 'receipt',
                component: Receipt,
                meta: {
                    reload: true,
                },
            },
            {
                path: '/:transactionTsid',
                name: 'stripe',
                component: Stripe,
                meta: {
                    reload: true,
                }
            },
            {
                path: '/p/:transactionTsid',
                name: 'paypal',
                component: Paypal,
                meta: {
                    reload: true,
                }
            },
            {
                path: '/r/:transactionTsid',
                name: 'razorpay',
                component: Razorpay,
                meta: {
                    reload: true,
                }
            },
        ],
    }
]

const router = new VueRouter({
    routes,
    mode: 'history',
})

export default router
