<template>
  <div id="app">
    <notifications group="foo" />
    <Navigation/>
  </div>
</template>


<script>
import Navigation from './components/navigation'

export default {
  components: {
    Navigation
  }
}

</script>


<style>
@import "assets/css/boxicons/css/boxicons.min.css";
</style>
