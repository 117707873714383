<template>
  <div class="container">

    <div v-if="(this.activeSuccess || this.saveCardSuccess) && this.showHtml">
      <Receipt :transaction="transactionDetails" :settings="settings"/>
      <p v-if="transactionDetails?.payment_component !== 'MANUAL_INVOICE' && this.activeTimer" class="p_label">Redirecting in {{
          timerCount
        }}...</p>
    </div>

    <div v-if="this.activeFailed">
      <LottieAnimation
          ref="anim"
          :animationData="require('@/assets/lottie/payment-failed.json')"
          :loop="3"
          :autoPlay="true"/>
      <p class="p_label">{{ this.errorMsg }}</p>
    </div>

    <b-overlay :show="showOverlay" rounded="sm">
      <div v-if="!showHtml" class="main_container"></div>
      <div class=" row justify-content-center" v-if="showHtml"
           :style="{'display': activePaymentPage ? 'flex': 'none'}">
        <div class="col-xl-6">
          <div class="card margin_top p-4">

            <div>
              <div class="row justify-content-center mb-4">
                <img class="img-fluid" style="width: 120px"
                     v-if="this.settings?.logo_url" :src="this.settings?.logo_url"
                     alt="logo"/>
              </div>

              <div id="paypal-button" v-if="!isSaveCard"></div>

              <div class="mt-2 mb-2 text-center" v-if="!isSaveCard">OR</div>

              <div id="card-form" class="card_container">
                <div id="card-name-field-container"></div>
                <div id="card-number-field-container"></div>
                <div id="card-expiry-field-container"></div>
                <div id="card-cvv-field-container"></div>
                <button id="multi-card-field-button" class="btn_call btn_call_inner" type="button">
                  {{
                    transactionDetails?.payment_component === 'SAVE_CARD' ? 'Save Card' : (transactionDetails?.currency_symbol) + (transactionDetails?.amount ? (transactionDetails?.amount / 100).toFixed(2) : '') + ' Pay Now'
                  }}
                </button>
              </div>

              <div style="font-size: x-small; color: gray;padding: 8px">
                This order process is conducted by online reseller & Merchant of Record, Stykite. Your data will be
                shared with {{ this.settings?.merchant_name }} for order fulfilment. Stykite, Inc, Suite 484, 2093A,
                Philadelphia Pike,
                Claymont,
                Delaware 19703
                <a target="_blank" :href="tandc">Terms & Conditions</a>
                &nbsp;
                <a target="_blank" :href="privacyPolicy">Privacy Policy</a>
              </div>

            </div>

          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {useVuelidate} from '@vuelidate/core'
import LottieAnimation from 'lottie-web-vue'
import axios from "axios";
import Receipt from "../components/Receipt/index.vue";


export default {
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      showHtml: false,
      showOverlay: false,
      activeTimer: false,
      activeSuccess: false,
      activeFailed: false,
      activePaymentPage: true,
      saveCardSuccess: false,
      timerCount: 3,
      errorMsg: null,
      tandc: process.env.VUE_APP_TERMS_AND_CONDITIONS,
      privacyPolicy: process.env.VUE_APP_PRIVACY_POLICY,
      transactionTsid: null,
      transactionDetails: null,
      settings: null,
      paypalPaymentMode: 'WALLET'
    }
  },
  components: {
    Receipt,
    LottieAnimation
  },
  validations() {
    return {}
  },
  computed: {
    isSaveCard() {
      return this.transactionDetails && this.transactionDetails.amount === 0
          && this.transactionDetails.payment_component === 'SAVE_CARD'
    }
  },
  async mounted() {
    this.showOverlay = true
    this.transactionTsid = this.$route.params.transactionTsid
    this.transactionDetails = await this.getTransactionDetails();
    await this.getSettings();
    this.showHtml = true

    // show success page if transaction already completed
    if (this.transactionDetails.status === 'SUCCESS') {
      this.showOverlay = false
      this.activePaymentPage = false
      if (this.transactionDetails?.payment_component === 'SAVE_CARD') {
        this.saveCardSuccess = true
      } else {
        this.activeSuccess = true
      }
      return
    }

    //paypal init
    const clientId = await this.getPaypalKey();
    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?components=buttons,card-fields&client-id=${clientId}`;
    script.addEventListener('load', this.paypalSetLoaded);
    document.body.appendChild(script);
    this.showOverlay = false
  },
  beforeDestroy() {
  },
  watch: {
    timerCount: {
      handler(value) {
        if (this.activeTimer) {
          if (value > 0) {
            setTimeout(() => {
              this.timerCount--
            }, 1000)
          } else {
            if (this.transactionDetails?.payment_component !== 'MANUAL_INVOICE') {
              window.location = this.settings?.redirect_url_after_payment
            }
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    paypalSetLoaded() {

      let cardField = null;
      if (this.isSaveCard) {
        cardField = window.paypal.CardFields({
          createVaultSetupToken: this.createVaultSetupToken,
          onApprove: this.createVaultPaymentToken,
        });
      } else {

        window.paypal
            .Buttons({
              style: {
                layout: 'horizontal',
                color: 'gold',
                shape: 'pill',
                label: 'pay',
                tagline: false
              },
              createOrder: this.createOrder,
              onApprove: this.onApprove,
            })
            .render('#paypal-button');

        cardField = window.paypal.CardFields({
          createOrder: this.createOrder,
          onApprove: this.onApprove,
        });
      }

      // Render each field after checking for eligibility
      if (cardField.isEligible()) {
        const nameField = cardField.NameField();
        nameField.render("#card-name-field-container");

        const numberField = cardField.NumberField();
        numberField.render("#card-number-field-container");

        const cvvField = cardField.CVVField();
        cvvField.render("#card-cvv-field-container");

        const expiryField = cardField.ExpiryField();
        expiryField.render("#card-expiry-field-container");

        // Add click listener to submit button and call the submit function on the CardField component
        document
            .getElementById("multi-card-field-button")
            .addEventListener("click", () => {
              this.paypalPaymentMode = 'CARD'
              this.showOverlay = true
              cardField.submit().catch((error) => {
                this.showOverlay = false
                alert(
                    `Sorry, your transaction could not be processed...<br><br>${error}`,
                );
              });
            });
      } else {
        // Hides card fields if the merchant isn't eligible
        document.querySelector("#card-form").style = "display: none";
      }

    },
    async createOrder() {
      try {
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/paypal/order/${this.transactionTsid}`, {mode: this.paypalPaymentMode})
        return data?.value?.id
      } catch (e) {
        console.log(e)
      }
    },
    async onApprove() {
      try {
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/paypal/order/${this.transactionTsid}/capture`)
        this.showOverlay = false
        this.activePaymentPage = false
        this.activeSuccess = true
        this.activeTimer = true
        this.timerCount--
        return data?.value?.id
      } catch (e) {
        console.log(e)
      }
    },
    async createVaultSetupToken() {
      try {
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/paypal/vault/${this.transactionTsid}/setup-tokens`)
        return data?.value?.id
      } catch (e) {
        console.log(e)
      }
    },
    async createVaultPaymentToken() {
      try {
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/paypal/vault/${this.transactionTsid}/payment-tokens`)
        this.showOverlay = false
        this.activePaymentPage = false
        this.saveCardSuccess = true
        this.activeTimer = true
        this.timerCount--
        return data?.value?.id
      } catch (e) {
        console.log(e)
      }
    },
    async getPaypalKey() {
      const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.transactionDetails?.company_tsid}/country/${this.transactionDetails?.country_tsid}/paypal`)
      if (data && data.value) {
        return data.value
      }
      return null
    },
    async getTransactionDetails() {
      try {
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/public/transaction/${this.transactionTsid}`)
        return data.value
      } catch (e) {
        console.log(e)
      }
      return null
    },
    async getSettings() {
      try {
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.transactionDetails?.company_tsid}/country/${this.transactionDetails?.country_tsid}/settings?type=WORKFLOW_SETTINGS`)
        if (data && data.value) {
          this.settings = {...data.value.setting}
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>

#card-error {
  color: red;
}

.labelStyle {
  font-family: 'Poppins';
  font-size: 15px;
  font-variant: normal;
  font-weight: lighter;
}

.p_label {
  font-family: 'Poppins';
  font-size: 15px;
  text-align: center;
  font-variant: normal;
  font-weight: lighter;
}

.card {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: 0.8rem;
  padding: 10px;
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, .08), 0 5px 15px 0 rgba(0, 0, 0, .12);
}

.border {
  border-radius: 0.8rem;
  border: 1px solid #bdd1e5 !important;
}

.margin_top {
  margin-top: 1rem !important
}

.btn_call {
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  border-radius: 8px;
  background: #4716DF !important;
  border: 1px solid #FFFFFF;
}

.btn_call_inner {
  color: #FFFFFF !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.lineHeight {
  line-height: 40px !important;
}

.lineHeight:focus-visible {
  outline: 1px solid #ffffff !important;
}

.lineHeight:focus {
  outline: 1px solid #ffffff !important;
  box-shadow: 0 0 0 0.5rem #ffffff !important;
}

.card_details {
  width: 50% !important;
  margin: auto !important;
}

.card_container {
  text-align: center;
}

.main_container {
  height: 90vh !important;
}

</style>
