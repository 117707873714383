import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { createPinia, PiniaVuePlugin } from 'pinia'
import Notifications from 'vue-notification'

import LottieAnimation from 'lottie-web-vue'

Vue.use(LottieAnimation); // add lottie-animation to your global scope



Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(PiniaVuePlugin)
Vue.use(Notifications)
const pinia = createPinia()
App.router = router

new Vue({
  render: h => h(App),
  pinia,
}).$mount('#app')
