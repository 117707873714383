function setValueWithMissingObject(obj, path, value) {
    const keys = path.split('.');
    let current = obj;

    // Iterate through all but the last key in the path
    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];

        // Create the key if it doesn't exist and it's not an array
        if (!current[key] || typeof current[key] !== 'object') {
            current[key] = {};
        }

        current = current[key];
    }

    // Set the value at the last key
    current[keys[keys.length - 1]] = value;
}

module.exports = {setValueWithMissingObject}
